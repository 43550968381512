import { createTheme } from '@mui/material/styles'
import PoppinsWoff2 from 'assets/fonts/Poppins-Regular.woff2'
import PoppinsSemiBoldWoff2 from 'assets/fonts/Poppins-SemiBold.woff2'
import PoppinsBoldWoff2 from 'assets/fonts/Poppins-Bold.woff2'

import { createBreakpoints } from '@mui/system'

export const theme = createTheme({
  breakpoints: createBreakpoints({
    values: {
      xs: 0,
      sm: 700,
      md: 1000,
      lg: 1200,
      xl: 1400,
    },
  }),
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: 48,
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
    },
    h3: {
      fontSize: 28,
      fontWeight: 600,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('PoppinsWoff2'), url(${PoppinsWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('PoppinsSemiBoldWoff2'), url(${PoppinsSemiBoldWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('PoppinsBoldWoff2'), url(${PoppinsBoldWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          fontWeight: 700,
          textTransform: 'none',
          boxShadow: 'none',
        },
        sizeLarge: {
          padding: '12px 28px',
          borderRadius: 40,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#F23D3D',
    },
    secondary: {
      main: '#FFF',
    },
  },
})
